

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}


*, ::after, ::before {
    box-sizing: border-box;
}

img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: none;
}
a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
}

body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px !important;
    font-weight: 400;
    font-family: Inter, Helvetica, sans-serif;
}

body {
    --fc-event-border-color: var(#1B84FF;);
    --fc-event-bg-color: var(#1B84FF);
    --fc-event-text-color: var(ffffff;);
}